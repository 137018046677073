<template>
  <div class="mx-4 mt-4">
    <div class="card col-12">
      <div class="mt-4">
        <iframe
          :src="iframeUrl"
          style="width: 100%; min-height: calc(100vh - 170px)"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  computed: {
    ...mapGetters({
      me: "profile/me",
    }),
    iframeUrl() {
      if (this.me.restrict_to_establishments) {
        const establishmentParams = this.me.allowedEstablishments
          .map((est) => `var-establishments=${est.id}`)
          .join("&");
        return `${process.env.VUE_APP_REPORTS_MY_CASH_REGISTERS_URL}?orgId=1&kiosk&${establishmentParams}&var-cash_registers=$__all`;
      }
      return `${process.env.VUE_APP_REPORTS_MY_CASH_REGISTERS_URL}?orgId=${this.me.orgId}&kiosk&var-cash_registers=$__all&var-establishments=$__all`;
    },
  },

  data() {
    return {};
  },
};
</script>
<style></style>
